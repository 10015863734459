import * as React from "react";
import {
    TopToolbar,
    FilterButton,
    CreateButton,
    List, Datagrid, useListContext,
    TextField, BooleanField,
    TextInput,
    useDataProvider,
    SelectInput,
    FunctionField,
    NullableBooleanInput
} from 'react-admin';
import PostForm from "./Form";
import _exporter from "../exporter";
import { RaPagination } from "../../components/ra-list/RaList";
import RaExporter from "../../components/ra-list/RaExporter";
import { getRaStore, setRaStore } from "../../utils/common";
import { formatLocalTime } from "../../components/ex_dayjs";

const FormView = () => <PostForm></PostForm>;

const ListActions = () => {
    const { filter, sort, resource } = useListContext();
    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
            <RaExporter resource={resource}
                exportFile={{ columns: ['Email', 'Name', 'Pwd_TM', 'Pwd_3TM', 'Address', 'City', 'State', 'Zip', 'Phone', 'Notes', 'Modified', 'ModifiedBy'] }}
                params={{ filter, sort }} />
        </TopToolbar>
    );
};

const RAList = () => {
    const dataProvider = useDataProvider();
    const [dictionaries, setDictionaries] = React.useState({
        category: [],
        subCategory: []
    });

    React.useEffect(() => {
        getDictionary('category', 'GetAAT_Category');
        getDictionary('subCategory', 'GetAAT_SubCategory');
    }, []);

    function getDictionary(key, action) {
        let dictionary = getRaStore(action);
        if (dictionary) {
            setDictionaries((prev) => ({ ...prev, [key]: dictionary }));
        } else {
            dataProvider
                .fetchData('AAT_PrimaryAccount', action)
                .then((response) => {
                    if (response.status !== 200 && response.data) {
                        let enumData = Object.entries(response.data).map(([value, name]) => ({ id: value, name: name }));
                        setDictionaries((prev) => ({ ...prev, [key]: enumData }));
                        setRaStore(action, enumData);
                    }
                })
                .catch(console.error);
        }
    }

    const postFilters = [
        <TextInput source="email" alwaysOn />,
        <TextInput source="name" alwaysOn />,
        <SelectInput source="category" choices={dictionaries.category} alwaysOn />,
        <SelectInput source="sub_Category_equals" label="Sub Category" choices={dictionaries.subCategory} alwaysOn />,
        <NullableBooleanInput label="Status" source="status" alwaysOn />,
        <TextInput label="Pwd TM" source="pwd_TM_contains" />,
        <TextInput label="Pwd 3TM" source="pwd_3TM_contains" />,
        <TextInput label="CC Type" source="cC_Type_contains" />,
        <TextInput source="address" />,
        <TextInput source="city" />,
        <TextInput source="state" />,
    ];

    return (
        <List title="AAT Primary accounts"
            storeKey={false}
            disableSyncWithLocation
            exporter={(records) => _exporter(records,
                ['Email', 'Name', 'Pwd_TM', 'Pwd_3TM', 'Address', 'City', 'State', 'Zip', 'Phone', 'Notes', 'Modified', 'ModifiedBy'])}
            perPage={25}
            pagination={<RaPagination />}
            sort={{ field: 'modified', order: 'DESC' }}
            filters={postFilters}
            filterDefaultValues={{ status: true }}
            actions={<ListActions />}>
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="email" />
                <TextField source="name" />
                <TextField source="pwd_TM" label="Pwd TM" />
                <TextField source="pwd_3TM" label="Pwd 3TM" />
                <TextField source="cC_Type" label="CC Type" />
                <TextField source="category" />
                <TextField source="sub_Category" label="Sub Category" />
                {/* <TextField source="address" /> */}
                <TextField source="city" />
                <TextField source="state" />
                {/* <TextField source="zip" />
                <TextField source="phone" /> */}
                {/* <TextField source="notes" /> */}
                <BooleanField source="status" />
                <FunctionField source="modified" render={record => formatLocalTime(record.modified)} />
                <TextField source="modifiedBy" />
            </Datagrid>
        </List>
    )
}
const AATPrimaryAccountPage = {
    name: 'AAT_PrimaryAccount',
    create: FormView,
    edit: FormView,
    list: RAList
};
export default AATPrimaryAccountPage;