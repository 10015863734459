import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import RaList from '../../components/ra-list/RaList';
import RenderControls from '../../components/ra-list/RenderControls';
import { Datagrid, TextField, BooleanField, FunctionField, useDataProvider } from 'react-admin';
import { formatLocalTime } from '../../components/ex_dayjs';
import { autoLoad, getRaStore, setRaStore } from '../../utils/common';
import { loadSourceType, loadExchanges, loadDeliveryMethod } from '../../utils/common';
import ApplyFor from './components/apply-for';
import EditRule from './components/edit-rule';
import { getRule } from './rules/rule';

const DynamicRule = () => {
    const dataProvider = useDataProvider();
    const refbtnFilter = useRef(null);
    const [marketType, setMarketType] = useState([]);
    const [category, setCategory] = useState([]);
    const [exchanges, setExchange] = useState([]);
    const [aatPAccounts, setAatPAccounts] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [sortBy] = useState(['modified desc']);
    const [rcDefault] = useState({ categoryId: 1, skipTag: null });//skipTag = Active
    const [editRuleDialog, setEditRuleDialog] = useState({ open: false, record: {}, resource: "", isEdit: undefined })
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [key, setKey] = useState('');
    const [categoryId, setCategoryId] = useState();
    const [ruleInfo, setRuleInfo] = useState({});

    React.useEffect(() => {
        setKey(new Date());
        let category = getRaStore('Category');
        if (category == null) {
            setIsLoadingCategory(true);
            dataProvider.fetchData('DynamicAdjustRule', 'Category').then((response) => {
                if (response.status !== 200 && response.data) {
                    let formatedCategory = response.data?.map(cte => ({ id: cte.id, name: cte.name + ' - ' + cte.description }))
                    setCategory(formatedCategory);
                    setRaStore('Category', formatedCategory);
                    setIsLoadingCategory(false);
                    autoLoad(refbtnFilter);
                }
            }).catch((error) => console.log(error));
        }
        else {
            setCategory(category);
            autoLoad(refbtnFilter);
        }
        setCategoryId(rcDefault.categoryId);

        loadSourceType(dataProvider, (response) => {
            let formattedMarketType = response?.map(x => ({ name: x.id, label: x.name }))
            setMarketType(formattedMarketType);
        }, false);

        loadExchanges(dataProvider, (response) => {
            let convert = response.map(x => ({ id: x.id.toString(), name: x.name }));
            setExchange(convert);
        });

        loadDeliveryMethod(dataProvider, (response) => {
            let delivery = response.map(x => ({ name: x.id + "", label: x.name }));
            setDelivery(delivery);
        });

        // load aat primary accounts
        dataProvider
            .fetchData('AAT_PrimaryAccount', 'Filter?$filter=status eq true')
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    let arr = response.data.items.filter(x => x.category != 'Dummy Accts').map(x => x.email);
                    setAatPAccounts(arr);
                }
            })
            .catch(console.error);
    }, []);

    React.useEffect(() => {
        console.log('categoryId, marketType', categoryId, marketType);
        if (categoryId && marketType.length) {
            let _rule = getRule({ categoryId, marketType, aatPAccounts, delivery });
            setRuleInfo(_rule);
        }
    }, [categoryId, marketType]);

    React.useEffect(() => {
        console.log(`ruleInfo `, ruleInfo);
    }, [ruleInfo]);

    const openEditRule = (id, resource, record) => {
        setEditRuleDialog({ ...editRuleDialog, open: true, record: record, resource: resource, isEdit: true });
    }

    const openNewRule = (id, resource, record) => {
        setEditRuleDialog({ ...editRuleDialog, open: true, record: {}, resource: resource, isEdit: false });
    }

    const closeDialog = () => {
        setEditRuleDialog({ ...editRuleDialog, open: false });
    }

    const handleCategory = (e) => {
        setCategoryId(e.value);
        autoLoad(refbtnFilter);
    }

    const controls = [
        { component: 'select', name: 'categoryId', label: 'Category', choices: category, isEmptyOption: false, xs: 2, onChange: handleCategory },
        { component: 'number', name: 'id', label: 'ID' },
        { component: 'text', name: 'name', label: 'Name', xs: 2 },
        { component: 'autocomplete', name: 'applyfor_contains', label: 'Apply for', xs: 2, choices: exchanges },//apply for which exchange
        { component: 'text', name: 'createdby', label: 'Created By' },
        { component: 'checkbox', name: 'skipTag', label: 'Active', nullable: true }
    ]

    return (
        <>
            <RaList
                perPage={25}
                title={'Dynamic Adjustment Rule'}
                sortby={sortBy}
                filters={
                    <RenderControls
                        defaultValues={rcDefault}
                        actions={
                            <Button onClick={openNewRule}>+ New Rule</Button>
                        }
                        controls={controls}
                        refFilter={refbtnFilter}
                        exportFile={{
                            filename: `${ruleInfo.name}-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['id', 'name', 'condition', 'action', 'applyMarket', 'createdby', 'modified', 'category', 'active'],
                            limit: 1000000
                        }}
                    />
                }
            >
                {isLoadingCategory && <p>Loading category ...</p>}
                <Datagrid bulkActionButtons={false} rowClick={openEditRule} key={key}>
                    <TextField source="id" label={'ID'} />
                    <TextField source="name" label={'Name'} />
                    <ApplyFor source="applyfor" label={'Apply For'} exchanges={exchanges} />
                    <TextField source="createdby" label={'Created By'} />
                    <FunctionField label='Modified' source='modified' render={record => (formatLocalTime(record.modified))} />
                    <BooleanField source="active" label={'Active'} />
                </Datagrid>
                <EditRule info={editRuleDialog} closeDialog={closeDialog} refbtnFilter={refbtnFilter} exchanges={exchanges} ruleInfo={ruleInfo} dataProvider={dataProvider} />
            </RaList >
        </>
    );
}

const DynamicAdjustRulePage = {
    name: 'DynamicAdjustRule',
    list: DynamicRule
}

export default DynamicAdjustRulePage