import * as React from 'react';
import './query.css';
import { TextField, Select, MenuItem, Autocomplete, Chip, Box } from '@mui/material';
import { grey, deepOrange } from "@mui/material/colors";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { nullValueOperator } from './querybuilder.config'
import { useValueEditor } from 'react-querybuilder';
import { formatTime } from '../ex_dayjs'

const defaultTags = ['Skip', 'Manual Review'];
const getStyles = (tagName) => {
    switch (tagName.trim()) {
        case 'skip':
            return grey[500];
        default:
            return deepOrange[400];
    }
}

const MValueEditor = ({
    handleOnChange,
    operator,
    listsAsArrays = true,
    value,
    className,
    ...props
}) => {
    const { valueAsArray, multiValueHandler } = useValueEditor({ handleOnChange, value, listsAsArrays: true });
    if (nullValueOperator.includes(operator)) return null;
    const editorType = props.fieldData.valueEditorType ?? props.fieldData.input;
    const placeHolderText = props.fieldData?.placeholder ?? '';
    const type = props.fieldData.type === 'integer' ? 'number' : 'text';

    if (operator === 'between' || operator === 'not_between') {
        const editors = ['from', 'to'].map((key, i) => {
            if (editorType === 'text') {
                if (props.fieldData.type === 'date' && props.fieldData.inputType === 'date') {
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                className={className}
                                value={valueAsArray[i] ?? ''}
                                onChange={(newValue) => {
                                    multiValueHandler(newValue, i)
                                }}
                                renderInput={(params) => <TextField variant="standard" className={className} {...params} />}
                            />
                        </LocalizationProvider>);
                } else if (props.fieldData.type === 'date' && props.fieldData.inputType === 'time') {
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                value={formatTime(valueAsArray[i], ['HH:mm:ss'], 'hh:mm A') ?? ''}
                                onChange={(newValue) => multiValueHandler(newValue, i)}
                                renderInput={(params) => <TextField variant="standard" className={className} {...params} />}
                            />
                        </LocalizationProvider>
                    );
                }
                return (
                    <TextField
                        key={key}
                        type={type}
                        variant="standard"
                        placeholder={placeHolderText}
                        className={className}
                        value={valueAsArray[i] ?? ''}
                        disabled={props.disabled}
                        onChange={(e) => multiValueHandler(e.target.value, i)}
                    />
                );
            } else if (editorType === 'select') {
                return (
                    <Select
                        disabled={props.disabled}
                        className={className}
                        variant="standard"
                        id="demo-simple-select-standard"
                        value={valueAsArray[i] ?? ''}
                        onChange={(e) => multiValueHandler(e.target.value, i)}
                    >
                        {props.values.map((x) => (
                            <MenuItem value={x.name}>{x.label}</MenuItem>
                        ))}
                    </Select>);
            }
        });

        return (
            <Box sx={{
                "& > :not(style)": { mr: 1, minWidth: "20ch" }
            }}
                noValidate
                autoComplete="off">
                {editors[0]}
                {editors[1]}
            </Box>
        );
    }

    switch (editorType) {
        case 'text':
            if (props.fieldData.type === 'date') {
                if (props.fieldData.inputType !== 'time')
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                className={className}
                                value={value}
                                onChange={(newValue) => {
                                    handleOnChange(newValue)
                                }}
                                renderInput={(params) => <TextField variant="standard" className={className} {...params} />}
                            />
                        </LocalizationProvider>);
                else return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <TimePicker
                            value={formatTime(value, ['HH:mm:ss'], 'hh:mm A')}
                            onChange={(newValue) => { handleOnChange(newValue); }}
                            renderInput={(params) => <TextField variant="standard" className={className} {...params} />}
                        />
                    </LocalizationProvider>
                );
            }

            return <TextField type={type} disabled={props.disabled} placeholder={props.fieldData.placeholder} value={value} onChange={(e) => handleOnChange(e.target.value)} id="standard-basic" variant="standard" className={className} />

        case 'select':
            return (
                <Select
                    disabled={props.disabled}
                    className={className}
                    variant="standard"
                    id="demo-simple-select-standard"
                    value={value}
                    onChange={(e) => handleOnChange(e.target.value)}
                >
                    {props.values.map((x) => (
                        <MenuItem value={x.name}>{x.label}</MenuItem>
                    ))}
                </Select>);
        case 'autocomplete':
            const defaultValue = Array.isArray(value) ? value : value ? value.split(',') : [];
            return (
                <Autocomplete
                    className={className}
                    multiple
                    disabled={props.disabled}
                    sx={{ minWidth: 200 }}
                    id="size-small-standard-multi"
                    size="small"
                    options={props?.values?.length > 0 ? props.values : defaultTags}
                    defaultValue={defaultValue}
                    onChange={(_, value) => handleOnChange(value.join(','))}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                label={option}
                                size="small"
                                key={option}
                                style={{ backgroundColor: getStyles(option.toString().toLowerCase()) }}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                        />
                    )}
                />
            );
        default:
            return <TextField disabled={props.disabled} placeholder={props.fieldData.placeholder} value={value} onChange={(e) => handleOnChange(e.target.value)} id="standard-basic" variant="standard" className={className} />
    }
};
export default MValueEditor;